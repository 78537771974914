@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
* {
    box-sizing: border-box;
}
:root {
    --primary: #9361fa;
    --secondary: rgb(250, 97, 124);
    --third: rgb(97, 211, 250);
    --secondaryText: rgb(26, 26, 31);
    --greyText: rgb(140, 140, 143);
    --white: #ffffff;
    --black: #000000;
    --mainFont: "Montserrat";
}
body {
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: 400;
    font-family: var(--mainFont);
    width: 100%;
    min-height: 100vh;
    display: flow-root;
}

/* BUTTONS */
.btn {
    padding: 10px 20px;
    border-radius: 8px;
    font-weight: 500;
    font-size: 1em;
    cursor: pointer;
    /*transition: 0.3s linear;*/
    display: inline-block;
}
.btn--secondary {
    background: var(--secondary);
    color: var(--white);
}
.btn.btn--outer {
    border: 2px solid var(--secondary);
    color: var(--secondary);
}
/* BUTTONS END */

.reviewBlock {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 350px auto;
    /* min-height: 100vh; */
    position: fixed;
    left: 0;
    top: 0;
}
.sidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    background: var(--black);
}
.logoIn {
    text-align: center;
}
.logoIn img {
    width: min(100%, 200px);
    height: 70px;
    margin: 0px 0px 20px 0px;
    object-fit: contain;
}
.sidebar__content {
    height: calc(100% - 70px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}
.sidebar__content img {
    height: 250px;
    margin: 0px 0px 30px 0px;
}
.sidebar h1 {
    font-size: 1.2rem;
    margin: 0px;
    color: var(--white);
    font-weight: 500;
    text-align: center;
}
.sidebar p {
    font-size: 0.875em;
    margin: 0px;
    color: var(--white);
    line-height: 1.5em;
}
.sidebarOffset {
    display: grid;
    place-items: center;
    padding: 20px;
}
.sidebar__content.sidebar__content--hasProgress {
    padding-bottom: 45px;
    position: relative;
}
.completedProgess {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
}
.completedProgess__text {
    text-align: right;
    font-weight: 400;
    letter-spacing: 0.5px;
}
.completedProgess__bar {
    width: 100%;
    height: 6px;
    background: var(--white);
    border-radius: 10px;
    margin: 15px 0px 0px 0px;
    position: relative;
    overflow: hidden;
    border: 1px solid var(--white);
}
.completedProgess__bar__bg {
    height: 6px;
    position: absolute;
    background: var(--secondary);
    transition: 0.3s linear;
    left: 0px;
    top: 0px;
}
.qsBlock {
    width: min(100%, 900px);
}
.qsBlock h4 {
    font-size: 1.2em;
    line-height: 1.5em;
    font-weight: 600;
    margin: 0px 0px 1em 0px;
}
.qsBlock .btn {
    margin-top: 10px;
    font-weight: 400;
    text-transform: uppercase;
}

/* CS COMPONENTS */
.csCheckboxWrap.two-sided {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px 20px;
}
:is(.csRadio, .csCheckbox) {
    width: 100%;
    display: inline-block;
    position: relative;
    padding-left: 25px;
    margin: 10px 0px;
}
:is(.csRadio__input, .csCheckbox__input) {
    width: 18px;
    height: 18px;
    margin: 0px;
    padding: 0px;
    outline: 0px;
    border: 0px;
    position: absolute;
    left: 0px;
    top: 1px;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
}
.csRadio__placeholder {
    width: 18px;
    height: 18px;
    border: 3px solid var(--secondary);
    position: absolute;
    left: 0px;
    top: 1px;
    border-radius: 20px;
}
.csRadio__placeholder:after {
    content: '';
    border-radius: 10px;
    background: var(--secondary);
    position: absolute;
    inset: 2.4px;
    transform: scale(0);
    transition: 0.3s linear;
}
.csCheckbox__placeholder {
    width: 18px;
    height: 18px;
    border: 2px solid var(--secondary);
    position: absolute;
    left: 0px;
    top: 1px;
    border-radius: 2px;
}
.csCheckbox__placeholder .fa {
    font-size: 0.876rem;
    color: var(--secondary);
    position: absolute;
    left: 0px;
    top: 1px;
    transform: scale(0);
    /*transition: 0.3s linear;*/
}
/* .csCheckbox__placeholder:after {
    content: '';
    width: 12px;
    height: 10px;
    border-radius: 0px;
    background: url('../images/tick.png') no-repeat center/contain;
    position: absolute;
    left: 1px;
    top: 2px;
    transform: scale(0);
    transition: 0.3s linear;
} */
.csRadio__input:checked + .csRadio__placeholder:after {
    transform: scale(1);
    transition: 0.3s linear;
}
/* .csCheckbox__input:checked + .csCheckbox__placeholder:after {
    transform: scale(1);
    transition: 0.3s linear;
} */
.csCheckbox__input:checked + .csCheckbox__placeholder .fa {
    transform: scale(1);
    /*transition: 0.3s linear;*/
}
:is(.csRadio__label, .csCheckbox__label) {
    font-size: 1em;
    line-height: 1.5em;
    font-weight: 500;
    cursor: pointer;
    /*transition: 0.3s linear;*/
}
:is(.csRadio__input, .csCheckbox__input):checked ~ :is(.csRadio__label, .csCheckbox__label) {
    color: var(--secondary);
    /*transition: 0.3s linear;*/
}
.csInput {
    width: 100%;
    height: 45px;
    padding: 5px 20px;
    border: 2px solid var(--secondary);
    border-radius: 5px;
    margin: 10px 0px;
    font-family: var(--mainFont);
    font-size: 1.15em;
    caret-color: var(--secondary);
}
.csInput:focus {
    outline: 0;
}
.csInput.error {
    border-color: #f20000;
    background: rgb(242 0 0 / 5%);
}
.csInput:is(:invalid, .error) + .btn {
    opacity: 0.2;
    pointer-events: none;
}
textarea.csInput {
    height: 200px;
    padding: 20px;
    resize: none;
}
:is(.radioImageBlockWrap, .checkboxImageBlockWrap) {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
}
.radioImageBlockWrap.moodScale {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    width: min(100%, 400px);
    gap: 15px;
    margin-bottom: 20px;
}
:is(.csRadioImage, .csCheckboxImage) {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
:is(.csRadioImage, .csCheckboxImage) img {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 10px;
    float: left;
    border: 2px solid transparent;
}
:is(.csRadioImage__input, .csCheckboxImage__input) {
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    outline: 0;
    z-index: 1;
    cursor: pointer;
    opacity: 0;
}
:is(.csRadioImage__input, .csCheckboxImage__input):checked + img {
    border: 2px solid var(--secondary);
}
.moodScale :is(.csRadioImage__input):checked + img {
    filter: drop-shadow(0px 1px 2px var(--secondary));
}
.moodScale :is(.csRadioImage__input) + img {
    padding: 6px;
    filter: drop-shadow(0px 1px 2px transparent);
}
.csRangeSlider {
    position: relative;
    height: 20px;
    width: 100%;
    border-radius: 100px;
    overflow: hidden;
}
.csRangeSlider__input {
    /* -webkit-appearance: none;
    appearnce: none; */
    background: transparent;
    border: 2px solid var(--secondary);
    border-radius: 100px;
    width: 100%;
    height: 20px;
    margin: 0px;
    cursor: pointer;
    accent-color:var(--secondary);
    position: relative;
    z-index: 1;
}
/* .csRangeSlider__placeholder {
    height: 20px;
    position: absolute;
    left: 0px;
    top: 0px;
    background: var(--secondary);
    border-radius: 100px 0px 0px 100px;
    z-index: 0;
    transition: 0.3s width linear;
} */
.csRangeSliderIndicators {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px;
    font-weight: 600;
    padding-left: 5px;
}
.csRangeSliderIndicators label:last-child {
    text-align: right;
}
.stepper {
    width: 100%;
    height: 20px;
    position: relative;
}
.stepper span {
    width: 2px;
    height: 10px;
    position: absolute;
    top: 3px;
    background: var(--black);
    left: calc(10% * var(--stepOrder));
}
.starRating {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 20px;
}
.starRating img {
    width: 50px;
    height: auto;
    cursor: pointer;
}
.starIn .fa {
    color: var(--secondary);
    font-size: 4em;
}
.csDropdown {
    position: relative;
    margin-bottom: 20px;
}
.csDropdown__label {
    width: 100%;
    display: inline-block;
    border: 1px solid var(--secondary);
    padding: 10px 15px;
    cursor: pointer;
    font-weight: 600;
    padding-right: 30px;
}
.csDropdown__label.active span {
        transform-origin: top;
        transform: rotate(180deg) translateY(-50%);
        transition: 0.3s linear;
}
.csDropdown__label span {
    width: 20px;
    height: 20px;
    background: transparent;
    position: absolute;
    right: 15px;
    top: 50%;
    transform-origin: top;
    transform: rotate(0deg) translateY(-50%);
    transition: 0.3s linear;
}
.csDropdown__label span:before, .csDropdown__label span::after {
    content: '';
    width:15px;
    height: 3px;
    background: var(--secondary);
    position: absolute;
    top: calc(50% - 7px);
}
.csDropdown__label span:before {
    transform: rotate(45deg);
    transform-origin: left;
    left: 0px;
}
.csDropdown__label span:after {
    transform: rotate(-45deg);
    transform-origin: right;
    right: 0px;
}
.csDropdown__in {
    width: 100%;
    height: auto;
    max-height: 250px;
    position: absolute;
    left: 0px;
    top: 100%;
    margin: 0px;
    list-style-type: none;
    padding: 0px;
    overflow: auto;
    background: var(--white);
    box-shadow: 0px 3px 12px -4px var(--secondary);
    transform-origin: top;
    transform: rotateX(90deg);
    transition: 0.3s linear;
}
.csDropdown__in.active {
    transform: rotateX(0deg);
    transition: 0.3s linear;
}
.csDropdown__in li {
    padding: 10px 15px;
    cursor: pointer;
    background: var(--white);
    transition: 0.3s linear;
}
.csDropdown__in li:hover {
    background: var(--secondary);
    color: var(--white);
    transition: 0.3s linear;
}
.fileUploader {
    width: 100%;
    height: 200px;
    position: relative;
    border-radius: 8px;
    border: 2px dashed var(--secondary);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fileUploader__input {
    margin: 0px;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
}
.promoterBlockWrap {
    margin-bottom: 20px;
}
.promoterBlock {
    width: 100%;
    border: 2px solid var(--black);
    border-radius: 8px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    overflow: hidden;
    position: relative;
    margin-bottom: 10px;
}
.bgBlock {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
    top: 0px;
    background: linear-gradient(89deg, #f80000, #ebeb00, #00ee00);
}
.promoterBlock span {
    display: flex;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1/1;
    background: var(--white);
    position: relative;
    z-index: 2;
    transition: 0.3s linear;
    font-size: 1.3em;
    font-weight: 600;
    color: var(--black);
    cursor: pointer;
}
.promoterBlock span:not(:last-child) {
    border-right: 2px solid var(--black);
}
.promoterBlock span.active {
    background: transparent;
    transition: 0.3s linear;
    color: var(--white);
    text-shadow: 0px 0px 3px var(--black);
}
.promoterIndicator {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.promoterIndicator label {
    font-size: 1em;
    font-weight: 600;
}
.promoterIndicator .negative {
    color: #f80000;
}
.promoterIndicator .positive {
    color: #00ee00;
}
.tqMessgae {
    font-size: 2.5rem;
    color: var(--primary);
    text-align: center;
    line-height: 3.7rem;
}
.welcomeMessgae{
    font-size: 20px;
    color: var(--primary);
}
.preLoader {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgb(0 0 0 / 85%);
    z-index: 2;
}
.preLoader img {
    width: min(100%, 80px);
    animation: 2s linear rotateIn infinite;
}

.mandatory-txt{
    margin-top: 30px;
    color: #E91E63;
    }
.remark-block{
    margin-top: 40px;
    margin-bottom: 40px
    }
.remark-question{
    font-size: 1.2em;
    line-height: 1.5em;
    font-weight: 600;
    margin: 0px 0px 1em 0px
    }
.sidebar__content__top{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.formBtnWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@keyframes rotateIn {
    0% {
        transform: rotateY(0deg);
    }
    20% {
        transform: rotateY(0deg);
    }
    80% {
        transform: rotateY(360deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

/* CS COMPONENTS END*/

@media (min-width: 771px) {
    body {
        overflow: hidden;
    }
    /* .reviewBlock {
        height: 100vh;
    } */
    .sidebar {
        height: 100%;
        overflow: auto;
    }
    .sidebarOffset {
        display: grid;
        place-items: center;
        padding: 20px;
        position: relative;
        padding-bottom: 70px;
    }
    .formBtnWrap {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0px;
        padding: 0px 20px;
        width: 100%;
        box-shadow: 0px 0px 12px -4px #666;
    }
    .qsBlock {
        width: min(100%, 900px);
        height: auto;
        max-height: calc(100vh - 85px);
        overflow: auto;
    }
    .qsBlock .btn {
        margin-top: 0px;
    }
}

@media (max-width: 770px) {
    body,html {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0px;
        left: 0px;
        overflow: hidden;
    }
    div#root {
        width: 100%;
        height: 100%;
        overflow: auto;
    }
    .reviewBlock {
        display: flex;
        flex-direction: column;
        /* min-height: auto; */
        overflow-y: auto;
    }
    .sidebar {
        padding: 30px 20px 120px 20px;
    }
    .sidebar h1 {
        margin-bottom: 20px;

    }
    .sidebarOffset {
        display: block;
        background: var(--white);
        width: calc(100% - 40px);
        margin: 0px 20px;
        margin-top: -100px;
        box-shadow: 0px 0px 12px -4px var(--secondary);
        margin-bottom: 120px;
    }
    .csRadio__placeholder:after {
        width: 8px;
        height: 8px;
        left: 2px;
        top: 2px;
    }
    :is(.radioImageBlockWrap, .checkboxImageBlockWrap) {
        grid-template-columns: 1fr 1fr;
    }
    .promoterBlock span {
        font-size: 1em;
    }
    .tqMessgae {
        font-size: 1.5rem;
        line-height: 2.5rem;
    }
    .csRangeSliderIndicators label {
        font-size: 0.725rem;
        /* width: 60px; */
    }
    .csCheckboxWrap.two-sided {
        grid-template-columns: 1fr;
    }
}
